import React from 'react';

import { useSelectedVariantContext } from '../../../TreatmentSelectorContext/TreatmentSelectorContext';

import ButtonBusy from '../../../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';

type ButtonBusyProps = React.ComponentProps<typeof ButtonBusy>;

type Props = Omit<ButtonBusyProps, 'children' | 'title'>;

const ReorderTreatmentButton = (props: Props) => {
    const { name } = useSelectedVariantContext();
    return (
        <ButtonBusy {...props} title={`Reorder ${name}`}>
            Reorder treatment
        </ButtonBusy>
    );
};

export default ReorderTreatmentButton;
