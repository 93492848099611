import React, { Fragment } from 'react';
import { PortableText } from 'next-sanity';

import { selectorScrollTo } from '../../../../helpers/scrollTo';
import { RedisTreatment } from '../../../../lib/redis/types/RedisTreatment';
import formatPrice from '../../../../helpers/formatPrice';
import { SanityBlockContent } from '../../../../helpers/text/sanityBlockContentToString';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import StarRating from '../../../_ui/_blocks/StarRating/StarRating';
import Tag from '../../../_ui/_blocks/Tag/Tag';
import { titleHighlightedComponents } from '../../../_ui/_blocks/HighlightedTitle/HighlightedTitle';

interface Props {
    title: string | SanityBlockContent;
    review: RedisTreatment['selectedReview'] | null;
    fromPrice: number;
    scrollToSelector?: string; // The selector the reviews click will scroll to.
    headingTag?: 'h3' | 'h1';
    percentageSaving?: number;
    gppcFromPrice?: string | null;
    className?: string;
    headerTextClassName?: string;
}

interface ReviewNoLinkProps {
    children: React.ReactNode;
}

interface ReviewWithLinkProps extends ReviewNoLinkProps {
    scrollToSelector: string;
}

interface HeadingProps extends Pick<Props, 'title' | 'headingTag'> {
    className: string;
}

/**
 * Reviews component that is not wrapped in a scroll link.
 */
const ReviewNoLink = ({ children }: ReviewNoLinkProps) => (
    <div className="flex items-center gap-050 h-[1.3125rem]">{children}</div>
);

/**
 * Reviews component that is wrapped in a scroll link.
 */
const ReviewWithLink = ({ scrollToSelector, children }: ReviewWithLinkProps) => {
    const onReviewsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        selectorScrollTo(scrollToSelector);
    };

    return (
        <a
            onClick={onReviewsClick}
            href="#treatment-reviews"
            className="flex items-center gap-050 h-[1.3125rem] underline hover:no-underline"
            title="View reviews"
        >
            {children}
        </a>
    );
};

const Heading = ({ headingTag: HeadingTag, title, className }: HeadingProps) => (
    <Typography as={HeadingTag} className={className}>
        {typeof title  === 'string'
            ? title
            : <PortableText components={titleHighlightedComponents()} value={title} />}
    </Typography>
)

/**
 * Header component for the treatment selector.
 */
const Header = ({
    title,
    review,
    fromPrice,
    gppcFromPrice,
    scrollToSelector,
    percentageSaving,
    headingTag = 'h3',
    className,
    headerTextClassName = 'font-400 type-500 leading-500 md:type-800 lg:type-1000'
}: Props) => {
    // If there is a scrollToSelector, wrap the reviews in a scroll link.
    const ReviewsWrapper = scrollToSelector ? ReviewWithLink : ReviewNoLink;

    const price = fromPrice || gppcFromPrice;

    return (
        <header className={className}>
            <div className="flex gap-100 justify-between">
                <Heading headingTag={headingTag} title={title} className={`font-alt ${headerTextClassName}`} />
            </div>
            {review || percentageSaving || price ? (
                <div className="flex gap-100 items-center justify-between mt-050">
                    {review ? (
                        <ReviewsWrapper scrollToSelector={scrollToSelector || ''}>
                            <StarRating rating={review.averageRating} loading="eager" />
                            <Typography size="090" lineHeight="none">
                                {review.numRatings} reviews
                            </Typography>
                        </ReviewsWrapper>
                    ) : null}
                    {price || percentageSaving ? (
                        <div className="ml-auto text-right">
                            {percentageSaving ? (
                                <Tag as="div" color="discountCode" className="!normal-case inline-block">
                                    Save up to {percentageSaving}%
                                </Tag>
                            ) : null}
                            {price ? (
                                <Typography
                                    as="div"
                                    typeset="emphasis"
                                    lineHeight="none"
                                    className="whitespace-nowrap"
                                >
                                    {gppcFromPrice || (
                                        <Fragment>
                                            <Typography size="080">from</Typography> {formatPrice(fromPrice)}
                                        </Fragment>
                                    )}
                                </Typography>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </header>
    );
};

export default Header;
