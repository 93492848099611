import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';

type Props = Pick<React.ComponentProps<typeof Attention>, 'className'>;

/**
 * Notification to the user that the given treatment is not available.
 */
const TreatmentUnavailable = ({ className }: Props) => (
    <Attention heading="Product unavailable" className={className}>
        <p>This product is currently unavailable.</p>
    </Attention>
);

export default TreatmentUnavailable;
