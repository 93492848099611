import { ProductVariant } from '../../../../../../../types/api/products/Product';
import calculateNumberOfDaysByPills from '../../helpers/calculateNumberOfDaysByPills/calculateNumberOfDaysByPills';
import calculateNumberOfPillsByDays from '../../helpers/calculateNumberOfPillsByDays/calculateNumberOfPillsByDays';

export interface TimedQuantityReducerState {
    modifiers: ProductVariant['timed_modifiers'];
    numberOfPills: number | string; // String should just be empty, as in ''.
    numberOfDays: number | string; // String should just be empty, as in ''.
}

interface UpdatePillsAction {
    type: 'UPDATE_PILLS';
    payload: {
        numberOfPills: TimedQuantityReducerState['numberOfPills'];
    };
}

interface UpdateDaysAction {
    type: 'UPDATE_DAYS';
    payload: {
        numberOfDays: TimedQuantityReducerState['numberOfDays'];
    };
}

interface ResetReducerState {
    type: 'RESET';
    payload: {
        modifiers: TimedQuantityReducerState['modifiers'];
        numberOfPills: TimedQuantityReducerState['numberOfPills'];
    };
}

export type ReducerAction = UpdatePillsAction | UpdateDaysAction | ResetReducerState;

/**
 * Calculates the initial number of days from the number of pills given in the initial state.
 */
export const createInitialTimedQuantityReducerState = ({ numberOfPills, modifiers }: TimedQuantityReducerState) => ({
    modifiers,
    numberOfPills,
    numberOfDays: typeof numberOfPills === 'number' ? calculateNumberOfDaysByPills(numberOfPills, modifiers) : '',
});

/**
 * Function used to update the number of pills and days in the reducer state.
 */
export const timedQuantityReducer = (state: TimedQuantityReducerState, action: ReducerAction) => {
    /* eslint-disable indent */
    switch (action.type) {
        case 'UPDATE_PILLS':
            return {
                ...state,
                numberOfPills: action.payload.numberOfPills,
                numberOfDays:
                    typeof action.payload.numberOfPills === 'number'
                        ? calculateNumberOfDaysByPills(action.payload.numberOfPills, state.modifiers)
                        : '',
            };
        case 'UPDATE_DAYS':
            return {
                ...state,
                numberOfPills:
                    typeof action.payload.numberOfDays === 'number'
                        ? calculateNumberOfPillsByDays(action.payload.numberOfDays, state.modifiers)
                        : '',
                numberOfDays: action.payload.numberOfDays,
            };
        case 'RESET': {
            return {
                modifiers: action.payload.modifiers,
                numberOfPills: action.payload.numberOfPills,
                numberOfDays:
                    typeof action.payload.numberOfPills === 'number'
                        ? calculateNumberOfDaysByPills(action.payload.numberOfPills, state.modifiers)
                        : '',
            };
        }
        default:
            return state;
    }
    /* eslint-enable indent */
};
