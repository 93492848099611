import { useReducer, useCallback } from 'react';

import { timedQuantityReducer, createInitialTimedQuantityReducerState, TimedQuantityReducerState } from './timedQuantityReducer';
import { useSelectedQuantityContext } from '../../../../TreatmentSelectorContext/TreatmentSelectorContext';
import { ProductVariant } from '../../../../../../../types/api/products/Product';

type SelectedQuantity = ReturnType<typeof useSelectedQuantityContext>;
type Modifiers = ProductVariant['timed_modifiers'];

/**
 * Hook containing a React reducer used to manage the number of pills and days in state.
 */
const useTimedQuantityReducer = (selectedQuantity: SelectedQuantity, modifiers: Modifiers) => {
    // Default pills and days are calculated in the initialiser function.
    const [reducerState, dispatch] = useReducer(
        timedQuantityReducer,
        {
            modifiers,
            numberOfPills: selectedQuantity?.quantity || '',
            numberOfDays: '',
        },
        createInitialTimedQuantityReducerState
    );

    const updateNumberOfPills = (pills: TimedQuantityReducerState['numberOfPills']) => {
        dispatch({
            type: 'UPDATE_PILLS',
            payload: {
                numberOfPills: pills,
            },
        });
    };

    const updateNumberOfDays = (days: TimedQuantityReducerState['numberOfDays']) => {
        dispatch({
            type: 'UPDATE_DAYS',
            payload: {
                numberOfDays: days,
            },
        });
    };

    const resetReducerState = useCallback((newModifiers: TimedQuantityReducerState['modifiers']) => {
        dispatch({
            type: 'RESET',
            payload: {
                modifiers: newModifiers,
                numberOfPills: selectedQuantity?.quantity || '',
            },
        });
    }, []);

    return {
        ...reducerState,
        updateNumberOfPills,
        updateNumberOfDays,
        resetReducerState,
    };
};

export default useTimedQuantityReducer;
