import React from 'react';

import Selector from '../Selector/Selector';
import {
    useSelectedVariantContext,
    useTreatmentSelectorUpdaterContext,
    useVariantsContext,
} from '../TreatmentSelectorContext/TreatmentSelectorContext';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import CheckmarkThin from '../../../_ui/_blocks/Icon/IconComponents/CheckmarkThin';

/**
 * Used to select the desired variant from a treatment.
 * @see https://www.figma.com/design/iJo8j00WDy8epUpwUl9RLT/Upstream-1.1?node-id=1664-98235&node-type=instance&m=dev
 */
const VariantSelector = () => {
    const variants = useVariantsContext();
    const selectedVariant = useSelectedVariantContext();
    const { updateVariant } = useTreatmentSelectorUpdaterContext();

    const handleChange = (value: number) => {
        updateVariant(variants.find((variant) => variant.id === value)!);
    };

    // If there is only one variant to select, we don't need to show the selector.
    if (variants.length === 1) {
        return null;
    }

    return (
        <Selector label="Select treatment" value={selectedVariant.id} onChange={handleChange}>
            {variants.map((variant) => (
                <Selector.Card key={variant.id} value={variant.id} title={`Select ${variant.name}`}>
                    {({ checked }) => (
                        <div className="flex gap-100 justify-between items-center">
                            <Typography size="090" className={checked ? 'font-600' : ''}>{variant.name}</Typography>
                            {checked
                                ? <CheckmarkThin size="xsmall" className="fill-layers-strong" />
                                : null}
                        </div>
                    )}
                </Selector.Card>
            ))}
        </Selector>
    );
};

export default VariantSelector;
