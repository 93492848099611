import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';
import { GenderKeys, HumanReadableGenders } from '../../../../../types/api/Gender';

type AttentionProps = Pick<React.ComponentProps<typeof Attention>, 'className'>;

interface Props extends AttentionProps {
    treatmentGender: GenderKeys;
    patientGender: GenderKeys;
}

/**
 * Notification to the user to say that the treatment the would like is not suitable for their given gender.
 */
const GenderNotification = ({ treatmentGender, patientGender, className }: Props) => {
    // Added this in case there any any live treatments with old / incorrect gender values, otherwise,
    // "undefined" is displayed to the user which we don't want.
    const heading = HumanReadableGenders[treatmentGender]
        ? `Sorry, we are only able to provide this treatment for ${HumanReadableGenders[treatmentGender]} patients`
        : `Sorry, we are unable to provide this treatment for your gender`;

    return (
        <Attention heading={heading} type="warning" className={className}>
            <p>
                You have indicated that your biological sex is
                <strong> {HumanReadableGenders[patientGender]}</strong>. Please create a separate account if this treatment is required for
                a different person to the individual registered on this account. If you believe that there is an issue with the biological
                sex recorded on your account, please contact our support team.
            </p>
        </Attention>
    );
};

export default GenderNotification;
