import React, { useEffect, useRef, Fragment } from 'react';

import {
    useSelectedQuantityContext,
    useSelectedVariantContext,
    useTreatmentSelectorUpdaterContext,
} from '../../TreatmentSelectorContext/TreatmentSelectorContext';
import modifyTimedQuantityWithNewQuantity from '../../TreatmentSelectorContext/hooks/useVariantQuantityReducer/helpers/modifyTimedQuantityWithNewQuantity/modifyTimedQuantityWithNewQuantity';
import useHandleKeyDown from './hooks/useHandleKeyDown/useHandleKeyDown';
import calculateNumberOfPillsByDays from './helpers/calculateNumberOfPillsByDays/calculateNumberOfPillsByDays';
import useTimedQuantityReducer from './hooks/useTimedQuantityReducer/useTimedQuantityReducer';

import NumberInput from '../../../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';
import Typography from '../../../../_ui/_blocks/Typography/Typography';
import useMaximumNumberOfPills from './hooks/useMaximumNumberOfPills/useMaximumNumberOfPills';
import SelectorPrice from '../../SelectorPrice/SelectorPrice';
import Icon from '../../../../_ui/_blocks/Icon/Icon';

/**
 * Component that allows a user to select a quantity of timed pills. They can either select the specific
 * number of pills they would like or put in the number of days they need treatment for and the number of
 * pills is calculated from the duration of days.
 */
const TimedQuantitySelector = () => {
    const { updateQuantity } = useTreatmentSelectorUpdaterContext();
    const selectedVariant = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();

    // Timed variant only has 1 quantity, so we can use the one attached to it instead of the quantity in
    // context.
    const quantity = selectedVariant.quantities[0];

    const { isDirty, handleKeyDown } = useHandleKeyDown();
    const { numberOfPills, numberOfDays, updateNumberOfPills, updateNumberOfDays, resetReducerState } = useTimedQuantityReducer(
        selectedQuantity,
        selectedVariant.timed_modifiers
    );

    const { maxNumberOfPills, maxNumberOfDays, isCurrentNumberOfPillsAllowed } = useMaximumNumberOfPills(selectedVariant, numberOfPills);

    // Is valid if the number of pills is not 0 and is less than or equal to the max allowed number of pills.
    const isValid = !!numberOfPills && isCurrentNumberOfPillsAllowed;

    // Updates the quantity in context when the number of pills changes.
    const handleNumberOfPillsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === '') {
            updateQuantity(modifyTimedQuantityWithNewQuantity(quantity, 0));
            updateNumberOfPills('');
            return;
        }

        const newNumberOfPills = Number(value);
        if (Number.isNaN(newNumberOfPills)) {
            return;
        }

        updateNumberOfPills(newNumberOfPills);
        updateQuantity(modifyTimedQuantityWithNewQuantity(quantity!, isCurrentNumberOfPillsAllowed ? newNumberOfPills : 0));
    };

    // Updates the quantity in context when the number of days changes.
    const handleNumberOfDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === '') {
            updateQuantity(modifyTimedQuantityWithNewQuantity(quantity, 0));
            updateNumberOfDays('');
            return;
        }

        const newNumberOfDays = Number(value);
        if (Number.isNaN(numberOfDays)) {
            return;
        }

        updateNumberOfDays(newNumberOfDays);

        const newNumberOfPills = isCurrentNumberOfPillsAllowed
            ? calculateNumberOfPillsByDays(newNumberOfDays, selectedVariant.timed_modifiers)
            : 0;

        updateQuantity(modifyTimedQuantityWithNewQuantity(quantity, newNumberOfPills));
    };

    // If the variant changes, reset the modifier, number of pills and number of days in the reducer state.
    // useRef is used to prevent this useEffect running on the first render.
    const hasDoneFirstRender = useRef(false);
    useEffect(() => {
        if (!hasDoneFirstRender.current) {
            hasDoneFirstRender.current = true;
            return;
        }
        resetReducerState(selectedVariant.timed_modifiers);
    }, [selectedVariant, resetReducerState]);

    useEffect(() => {
        if (+numberOfPills > maxNumberOfPills) {
            updateNumberOfPills(maxNumberOfPills);
            updateQuantity(modifyTimedQuantityWithNewQuantity(quantity, maxNumberOfPills));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <fieldset>
                <div className="flex gap-050 items-center">
                    <Icon icon="info-status" alt="Info" size="medium" />
                    <Typography as="p" size="070">
                        Enter the number of days you're in a malaria-risk area and we'll calculate how many pills you need.
                    </Typography>
                </div>
                <div className="flex gap-200 justify-between mt-150">
                    <NumberInput
                        id="number-of-days"
                        label="Number of days"
                        step="1"
                        min="0"
                        max={maxNumberOfDays || undefined}
                        className="w-full"
                        isDirty={isDirty}
                        isValid={isValid}
                        value={numberOfDays}
                        onKeyDown={handleKeyDown}
                        onChange={handleNumberOfDaysChange}
                    />
                    <NumberInput
                        id="number-of-pills"
                        label="Number of pills"
                        step="1"
                        min="1"
                        max={maxNumberOfPills || undefined}
                        className="w-full"
                        isDirty={isDirty}
                        isValid={isValid}
                        value={numberOfPills}
                        onKeyDown={handleKeyDown}
                        onChange={handleNumberOfPillsChange}
                    />
                </div>
                {!isCurrentNumberOfPillsAllowed ? (
                    <Typography
                        as="div"
                        typeset="subheading"
                        size="090"
                        color="error"
                        className="flex gap-050 items-center mt-050"
                    >
                        <Icon icon="error-status" alt="Warning" />
                        No more than {maxNumberOfPills} pills can be purchased.
                    </Typography>
                ) : null}
            </fieldset>
            <SelectorPrice price={selectedQuantity?.price || 0} />
        </Fragment>
    );
};

export default TimedQuantitySelector;
