import React from 'react';

import { useBasketBlockerContext, useSelectedVariantContext } from '../TreatmentSelectorContext/TreatmentSelectorContext';
import blockingTreatmentNamesToString from './BlockedByBasketNotification/helpers/blockingTreatmentNamesToString';
import { Product } from '../../../../types/api/products/Product';

import OdonlyNotification from './OdonlyNotification/OdonlyNotification';
import OutOfStockNotification from './OutOfStockNotification/OutOfStockNotification';
import AlreadyInBasketNotification from './AlreadyInBasketNotification/AlreadyInBasketNotification';
import BlockedByBasketNotification from './BlockedByBasketNotification/BlockedByBasketNotification';
import GenderNotification from './GenderNotification/GenderNotification';
import PrivatePrescriptionNotification from './PrivatePrescriptionNotification/PrivatePrescriptionNotification';
import RefillDiscrepanciesNotification from './RefillDiscrepanciesNotification/RefillDiscrepanciesNotification';

interface GenericNotification {
    key:
        | 'ODONLY_NOTIFICATION_KEY'
        | 'PRESCRIPTION_NOTIFICATION_KEY'
        | 'REFILL_DISCREPANCIES_NOTIFICATION_KEY'
        | 'TREATMENT_UNAVAILABLE_NOTIFICATION_KEY';
    component: typeof OdonlyNotification | typeof PrivatePrescriptionNotification | typeof RefillDiscrepanciesNotification;
}

interface Props {
    conditionName: Product['condition_name'];
    isPrivatePrescription?: boolean;
    excludes?: Array<'ODONLY' | 'REFILL_DISCREPANCIES' | 'QUANTITY_NOT_AVAILABLE'>;
}

/**
 * Displays a single basket blocker at a time to the user. If there are no basket blockers.
 */
const Notifications = ({ conditionName, isPrivatePrescription, excludes = [] }: Props) => {
    const selectedVariant = useSelectedVariantContext();
    const blockers = useBasketBlockerContext();

    const blocker = blockers.length ? blockers[0] : null;
    if (blocker) {
        /* eslint-disable indent */
        /* eslint-disable no-case-declarations */
        switch (blocker.type) {
            case 'OUT_OF_STOCK':
                return <OutOfStockNotification conditionName={conditionName} variant={selectedVariant} />;
            case 'ALREADY_IN_BASKET':
                return <AlreadyInBasketNotification />;
            case 'BLOCKED_BY_BASKET':
                const treatments = blockingTreatmentNamesToString(blocker.data.blockingBasketItemNames);
                return <BlockedByBasketNotification treatments={treatments} />;
            case 'GENDER_RESTRICTED':
                return <GenderNotification treatmentGender={blocker.data.treatmentGender} patientGender={blocker.data.patientGender} />;
            default:
                return null;
        }
        /* eslint-enable no-case-declarations */
        /* eslint-enable indent */
    }

    const notifications = [] as GenericNotification[];

    if (selectedVariant.type === 'ODONLY' && !excludes.includes('ODONLY')) {
        notifications.push({
            key: 'ODONLY_NOTIFICATION_KEY',
            component: OdonlyNotification,
        });
    }

    if (isPrivatePrescription) {
        notifications.push({
            key: 'PRESCRIPTION_NOTIFICATION_KEY',
            component: PrivatePrescriptionNotification,
        });
    }

    return notifications.length
        ? notifications.map(({ key, component: Notification }) => <Notification key={key} conditionName={conditionName} />)
        : null;
};

export default Notifications;
