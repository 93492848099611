import React, { HTMLAttributes } from 'react';
import clx from 'classnames';

import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';

import styles from './Attention.module.css';

type TypographyProps = React.ComponentProps<typeof Typography>;
type IconProps = React.ComponentProps<typeof Icon>;

enum StatusIcons {
   info = 'info-status',
   success = 'success-status',
   warning = 'warning-status',
   error = 'error-status',
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    icon?: IconProps['icon']
    type?: 'info' | 'warning' | 'error' | 'success';
    iconSize?: IconProps['size'];
    heading: string | null;
    headingSize?: TypographyProps['size'];
    children?: TypographyProps['children'];
    className?: string;
}

/**
 * Attention component
 * @see https://www.figma.com/design/HB2vMGpmieuLtPlJUEfFXi/Hippo-UI-kit-3.1?node-id=1461-1981&node-type=canvas&m=dev
 */
const Attention = ({
    type = 'info',
    iconSize = 'medium',
    icon,
    heading,
    headingSize = '090',
    children,
    className,
    ...rest
}: Props) => {
    // Use the icon if provided, otherwise, use the type icon.
    const classNames = clx(styles.attention, styles[type], className);

    return (
        <div {...rest} className={classNames}>
            {heading ? (
                <Typography as="h4" typeset="heading" size={headingSize} className="flex gap-050 items-center">
                    <Icon icon={icon || StatusIcons[type]} alt={type} size={iconSize} />
                    {heading}
                </Typography>
            ) : null}
            {children ?
                <Typography as="div" typeset="paragraph" size="090">{children}</Typography>
                : null}
        </div>
    );
};

export default Attention;
