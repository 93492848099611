import React, { useMemo } from 'react';

import { Product, ProductVariant } from '../../../types/api/products/Product';
import { TreatmentSelectorProvider, OnChange, OnChangeData } from './TreatmentSelectorContext/TreatmentSelectorContext';
import { InitialVariant } from './TreatmentSelectorContext/hooks/useVariantQuantityReducer/helpers/getDefaultVariant/getDefaultVariant';
import { useAuthContext } from '../../../data/context/authContext';

import TreatmentSelectorHeader from './TreatmentSelectorHeader/TreatmentSelectorHeader';
import TreatmentUnavailable from './Notifications/TreatmentUnavailable/TreatmentUnavailable';
import VariantSelector from './VariantSelector/VariantSelector';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import TreatmentSelectorForm, { OnSubmit, OnSubmitData } from './TreatmentSelectorForm/TreatmentSelectorForm';
import Notifications from './Notifications/Notifications';
import LoadingSkeleton from './LoadingSkeleton/LoadingSkeleton';
import ConsultationButton from './SelectorButtons/ConsultationButton/ConsultationButton';
import TreatmentPageButton from './SelectorButtons/TreatmentPageButton/TreatmentPageButton';
import BasketPreview from './BasketPreview/BasketPreview';
import SelectorPrice from './SelectorPrice/SelectorPrice';

// Added exports of types from this file so we only need to have 1 import when using this component.
export type SelectorOnSubmit = OnSubmit;
export type SelectorOnSubmitData = OnSubmitData;

// Added exports of types from this file so we only need to have 1 import when using this component.
export type SelectorOnChange = OnChange;
export type SelectorOnChangeData = OnChangeData;

interface Props {
    onSubmit: OnSubmit;
    treatment?: Product | null;
    isLoading?: boolean;
    isError?: boolean;
    children: React.ReactNode;
    onChange?: OnChange; // Function that runs when either the selected variant or quantity changes.
    className?: string;
    initialVariant?: InitialVariant; // A variant for the reducer state. If the variant is not found, a default will be calculated.
}

/**
 * Manages the different states to show the user. Shows the following states:
 * - isLoading: Show a loading state.
 * - isError: Show an error message.
 * - unavailable: Show a treatment unavailable message.
 * - children: Show the children passed into the treatment selector.
 * @see https://www.figma.com/design/iJo8j00WDy8epUpwUl9RLT/Upstream-1.1?node-id=3057-314677&node-type=canvas&m=dev
 */
const TreatmentSelector = ({ treatment, onChange, onSubmit, className, initialVariant, isLoading, isError, children }: Props) => {
    const { loadingPatient } = useAuthContext();

    const variants: ProductVariant[] = useMemo(() => {
        if (!treatment) {
            return [];
        }

        return treatment.variants.filter((variant) => variant.available).sort((a, b) => a.child_order - b.child_order); // Sorts by order given in CP.
    }, [treatment]);

    if (isLoading || loadingPatient) {
        return <LoadingSkeleton />;
    }

    // If the treatment has no variants, the treatment is unavailable.
    if (isError || variants.length === 0) {
        return <TreatmentUnavailable />;
    }

    return (
        <TreatmentSelectorProvider
            treatment={treatment!}
            variants={variants}
            onChange={onChange}
            initialVariant={initialVariant}
        >
            <TreatmentSelectorForm onSubmit={onSubmit} className={className}>
                {children}
            </TreatmentSelectorForm>
        </TreatmentSelectorProvider>
    );
};

// Added exports of required components from this file so we only need to have 1 import when using this
// component.
TreatmentSelector.Header = TreatmentSelectorHeader;
TreatmentSelector.VariantSelector = VariantSelector;
TreatmentSelector.QuantitySelector = QuantitySelector;
TreatmentSelector.Notifications = Notifications;
TreatmentSelector.ConsultationButton = ConsultationButton;
TreatmentSelector.TreatmentPageButton = TreatmentPageButton;
TreatmentSelector.BasketPreview = BasketPreview;
TreatmentSelector.Price = SelectorPrice;

export default TreatmentSelector;
