import { useMemo } from 'react';

import { ProductVariant } from '../../../../../../types/api/products/Product';
import { useBasketContext } from '../../../../../../data/context/basketContext/basketContext';
import { useAuthContext } from '../../../../../../data/context/authContext';
import getBasketBlockers from './helpers/getBasketBlockers/getBasketBlockers';

/**
 * Hook to get blockers that are stopping an item being added to the basket. Consumes the basket and patient
 * context.
 */
const useBasketBlockers = (variant: ProductVariant) => {
    // TODO: fix types
    // @ts-ignore
    const { basket } = useBasketContext();
    const { patient } = useAuthContext();

    const blockers = useMemo(() => getBasketBlockers(variant, basket, patient), [variant, basket, patient]);

    return blockers;
};

export default useBasketBlockers;
