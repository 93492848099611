import { GenderKeys, Genders } from '../../../../../../../../types/api/Gender';
import Patient from '../../../../../../../../types/api/patient/Patient';
import { ProductVariant } from '../../../../../../../../types/api/products/Product';

export interface GenderBlocker {
    type: 'GENDER_RESTRICTED';
    data: {
        treatmentGender: ProductVariant['gender'];
        patientGender: Patient['personal']['gender'];
    };
}

/**
 * Returns the gender restricted blocker if the treatment doesn't match the patients gender.
 */
const getGenderBlocker = (treatmentGender: ProductVariant['gender'], patientGender?: Patient['personal']['gender']) => {
    if (!patientGender || treatmentGender === 'n/a') {
        return null;
    }

    const allowedTreatmentGenders = [
        treatmentGender === Genders.male ? Genders.male : Genders.female,
        Genders.maleIdentifiesFemale,
        Genders.femaleIdentifiesMale,
    ] as GenderKeys[];

    if (allowedTreatmentGenders.includes(patientGender)) {
        return null;
    }

    return {
        type: 'GENDER_RESTRICTED',
        data: {
            treatmentGender,
            patientGender,
        },
    };
};

export default getGenderBlocker;
