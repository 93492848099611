import React, { Fragment, useRef } from 'react';
import { createPortal } from 'react-dom';

import useWindowSizeCheck from '../../../../hooks/useWindowSizeCheck';
import BasketPreviewCard from './BasketPreviewCard/BasketPreviewCard';

type BasketPreviewCardProps = React.ComponentProps<typeof BasketPreviewCard>;
type Props = Omit<BasketPreviewCardProps, 'onClick' | 'type'>;

/**
 * Displays the small basket preview card on mobile devices and uses a portal to append it to the end of the
 * body.
 */
const BasketPreview = (props: Props) => {
    // Hides the basket preview card on desktop devices.
    const [shouldHide] = useWindowSizeCheck(719, true);

    // A hidden div has been added so that the closest form can be found and a submit event dispatched. This
    // is done as the basket preview card containing the form submit button is rendered outside of the form
    // so it wont triggered a submit event when clicked.
    const divRef = useRef<HTMLDivElement>(null);

    if (shouldHide) {
        return null;
    }

    const handleClick = () => {
        divRef.current?.closest('form')?.dispatchEvent(
            new Event('submit', {
                bubbles: true,
                cancelable: true,
            })
        );
    };

    const portalElement = createPortal(<BasketPreviewCard {...props} onClick={handleClick} />, document.body, 'basket-preview');

    return (
        <Fragment>
            <div ref={divRef} className="hidden" />
            {portalElement}
        </Fragment>
    );
};

export default BasketPreview;
