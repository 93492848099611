import React from 'react';

import { iconSizes } from '../icon.config';

interface Props extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height'> {
    size?: keyof typeof iconSizes
};

const SvgWrapper = ({ size = 'small', viewBox = '0 0 48 48', ...rest }: Props) => (
    <svg width={iconSizes[size]} height={iconSizes[size]} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" {...rest} />
);

export default SvgWrapper;
