import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';

type Props = Pick<React.ComponentProps<typeof Attention>, 'className'>;

/**
 * Notification to the user to say that the treatment the would like is already in their basket.
 */
const AlreadyInBasketNotification = ({ className }: Props) => (
    <Attention heading="This treatment is already in your basket" type="warning" className={className}>
        <p>You can checkout with this treatment or change the quantity from the basket</p>
    </Attention>
);

export default AlreadyInBasketNotification;
