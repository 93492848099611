import { BlockedByBasketBlocker } from '../../../TreatmentSelectorContext/hooks/useBasketBlockers/helpers/getBlockedByBasketBlocker/getBlockedByBasketBlocker';

/**
 * Joins an array of strings to gether as a comma separated list but uses "and" instead of a comma before the
 * last value.
 */
const blockingTreatmentNamesToString = (blockingNames: BlockedByBasketBlocker['data']['blockingBasketItemNames']) => {
    const blockingNamesClone = [...blockingNames];

    if (blockingNamesClone.length === 0) {
        return '';
    }

    if (blockingNamesClone.length === 1) {
        return blockingNamesClone[0];
    }

    const lastItem = blockingNamesClone.pop();
    return `${blockingNamesClone.join(', ')} and ${lastItem}`;
};

export default blockingTreatmentNamesToString;
