import React from 'react';

import { useSelectedVariantContext } from '../../../TreatmentSelectorContext/TreatmentSelectorContext';

import ButtonBusy from '../../../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';

type ButtonBusyProps = React.ComponentProps<typeof ButtonBusy>;

type Props = Omit<ButtonBusyProps, 'children' | 'title' | 'type'>;

const SelectAndContinueButton = (props: Props) => {
    const { name } = useSelectedVariantContext();

    return (
        <ButtonBusy {...props} type="submit" title={`Select and continue with ${name}`}>
            Select and continue
        </ButtonBusy>
    );
};

export default SelectAndContinueButton;
