import { useState } from 'react';

/**
 * Returns a function to handle the key down event for the timed quantity selector. It also manages the is
 * dirty state which is set to true when the key down function is called.
 */
const useHandleKeyDown = () => {
    const [isDirty, setIsDirty] = useState(false);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Chrome allows the user to add these to a number input, but we don't want users to be able to.
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }

        if (!isDirty) {
            setIsDirty(true);
        }
    };

    return {
        isDirty,
        handleKeyDown,
    };
};

export default useHandleKeyDown;
