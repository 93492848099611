import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';

type AttentionProps = Pick<React.ComponentProps<typeof Attention>, 'className'>;

interface Props extends AttentionProps {
    treatments: string;
}

/**
 * Notification to the user to say that the treatment the would like is blocked by another item(s) in the
 * basket.
 */
const BlockedByBasketNotification = ({ treatments, className }: Props) => (
    <Attention heading="This treatment cannot be added to your basket" type="warning" className={className}>
        <p>
            You cannot add this treatment to your basket as it already contains {treatments}. You can open your basket below to amend your
            current products.
        </p>
    </Attention>
);

export default BlockedByBasketNotification;
