import React, { useRef } from 'react';

import sendSentryMessage from '@/lib/sentry/helpers/sendSentryMessage';

import { useIsRefillContext, useSelectedVariantContext } from '../TreatmentSelectorContext/TreatmentSelectorContext';
import QuantityTypes from '../../../../types/enums/QuantityTypes';

import PriceComparison from './PriceComparison/PriceComparison';
import RegularQuantitySelector from './RegularQuantitySelector/RegularQuantitySelector';
import TimedQuantitySelector from './TimedQuantitySelector/TimedQuantitySelector';

interface Props {
    disablePriceComparison?: boolean;
}

/**
 * Works out which quantity selector to render based on the selected variant.
 */
const QuantitySelector = ({ disablePriceComparison }: Props) => {
    const hasIssuedSentryError = useRef(false);
    const selectedVariant = useSelectedVariantContext();
    const isRefill = useIsRefillContext();

    const quantityType = selectedVariant?.quantity_type;

    // If there are no quantities then the treatment is out of stock.
    if (!selectedVariant?.quantities.length) {
        return null;
    }

    if (quantityType === QuantityTypes.timed) {
        return <TimedQuantitySelector />;
    }

    // If the price comparison is not disabled, treatment type is ODONLY and it's not a refill, then we need
    // to render the price comparison.
    if (!disablePriceComparison && selectedVariant?.type === 'ODONLY' && !isRefill) {
        return <PriceComparison />;
    }

    if (quantityType === QuantityTypes.regular) {
        return <RegularQuantitySelector />;
    }

    // If there's a selected variant in state but no quantity selector could be rendered, send a sentry error.
    if (selectedVariant?.id && !hasIssuedSentryError.current) {
        sendSentryMessage('Selected variant has no quantity selector to display.', (scope) => {
            scope.setExtra('Variant ID', selectedVariant.id);
        });

        hasIssuedSentryError.current = true;
    }

    return null;
};

export default QuantitySelector;
