import { Basket } from '../../../../../../../../types/api/basket/Basket';
import { BasketItem } from '../../../../../../../../types/api/basket/BasketItem';
import { ProductVariant } from '../../../../../../../../types/api/products/Product';

export interface BlockedByBasketBlocker {
    type: 'BLOCKED_BY_BASKET';
    data: {
        blockingBasketItemNames: Array<BasketItem['product_name']>;
    };
}

/**
 * Returns the blocked by basket blocker if there is a basket item that is stopping a treatment variant from
 * being added to the basket.
 */
const getBlockedByBasketBlocker = (variant: ProductVariant, basket?: Basket) => {
    const basketItems = basket?.items || [];

    if (basketItems.length === 0) {
        return null;
    }

    const blockingBasketItems = basketItems.reduce((acc, basketItem) => {
        const accClone = [...acc];

        // If the basket blocker IDs contain the variant ID, or vice versa,
        if (basketItem.blocker_ids.includes(variant.id) || variant.blocker_ids.includes(basketItem.reference)) {
            accClone.push(basketItem);
        }

        return accClone;
    }, [] as BasketItem[]);

    if (blockingBasketItems.length === 0) {
        return null;
    }

    return {
        type: 'BLOCKED_BY_BASKET',
        data: {
            blockingBasketItemNames: blockingBasketItems.map((basketItem) => basketItem.product_name),
        },
    };
};

export default getBlockedByBasketBlocker;
