import { useReducer, useMemo } from 'react';

import { createInitialVariantQuantityReducerState, variantQuantityReducer } from './variantQuantityReducer';
import { ProductVariant } from '../../../../../../types/api/products/Product';
import { InitialVariant } from './helpers/getDefaultVariant/getDefaultVariant';
import { useAuthContext } from '../../../../../../data/context/authContext';
import Patient from '../../../../../../types/api/patient/Patient';

interface Props {
    variants: ProductVariant[];
    initialVariant?: InitialVariant;
}

/**
 * Hook containing a React reducer used to manage the selected variant and quantity state. The quantity and
 * variant inside the reducer are split up into separate memos to reduce rerenders in the UI.
 */
const useVariantQuantityReducer = ({ variants, initialVariant }: Props) => {
    const { patient } = useAuthContext();

    // Default variant and quantity are calculated in the initialiser function.
    const [reducerState, dispatch] = useReducer(
        variantQuantityReducer,
        {
            refills: (patient as Patient | undefined)?.refills || [],
            isRefill: false,
            variants, // Only used to calculate the default values in the initialiser function.
            initialVariant, // Only used to calculate the default values in the initialiser function.
            variant: {} as ProductVariant, // This will always be a value;
            quantity: null,
        },
        createInitialVariantQuantityReducerState
    );

    // This memo will always change if the reducer state changes. We only want it to update if the variant
    // changes. To do this, the variant ID is given as the dependency instead of the variant its self.
    const variant = useMemo(() => reducerState.variant, [reducerState.variant.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // This memo will always change if the reducer state changes. We only want it to update if the quantity
    // changes. To do this, the quantity in the reducer state is stringified as a dependency.
    const quantity = useMemo(() => reducerState.quantity, [JSON.stringify(reducerState.quantity)]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        variant,
        quantity,
        isRefill: reducerState.isRefill,
        dispatch,
    };
};

export default useVariantQuantityReducer;
