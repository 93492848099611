import { roundToNearestHalf } from '../../../../../helpers/maths';

// Images are imported as NextJS can do more with imported images than images from a URL.
// @see https://nextjs.org/docs/app/api-reference/components/image
import FullStar from './Images/FullStar.svg';
import HalfStar from './Images/HalfStar.svg';
import EmptyStar from './Images/EmptyStar.svg';

/**
 * Creates an array of star image sources based on the rating out of 5.
 */
const getStarRating = (rating: number): string[] => {
    const roundedRating = roundToNearestHalf(rating);

    if (roundedRating > 4.5) {
        return Array(5).fill(FullStar);
    }

    if (!roundedRating) {
        return Array(5).fill(EmptyStar);
    }

    const isInteger = Number.isInteger(roundedRating);

    // Create an array of full stars.
    const starsArray = isInteger ? Array(roundedRating).fill(FullStar) : Array(roundedRating - 0.5).fill(FullStar);

    // Add a half star if the rating is not an integer.
    if (!isInteger) {
        starsArray.push(HalfStar);
    }

    // Add empty stars until the array is 5 items long.
    while (starsArray.length < 5) {
        starsArray.push(EmptyStar);
    }

    return starsArray;
};

export default getStarRating;
