import React from 'react';

import Selector from '../../Selector/Selector';
import {
    useSelectedQuantityContext,
    useSelectedVariantContext,
    useTreatmentSelectorUpdaterContext,
} from '../../TreatmentSelectorContext/TreatmentSelectorContext';
import useFormatQuantitiesForDisplay from '../../hooks/useFormatQuantitiesForDisplay/useFormatQuantitiesForDisplay';
import QuantityPricing from '../../QuantityPricing/QuantityPricing';

const RegularQuantitySelector = () => {
    const selectedVariant = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();
    const { updateQuantity } = useTreatmentSelectorUpdaterContext();

    const handleChange = (value: number) => {
        updateQuantity(selectedVariant.quantities.find(({ quantity }) => quantity === value)!);
    };

    const { quantities } = useFormatQuantitiesForDisplay(selectedVariant);

    return (
        <Selector label="Select quantity" value={selectedQuantity!.quantity} onChange={handleChange} data-testid="regular-quantity">
            {quantities.map((quantity) => {
                const key = `${quantity.quantity}-${quantity.label}`;

                return (
                    <Selector.Card key={key} value={quantity.quantity} title={`Select ${quantity.label}`}>
                        {() => (
                            <QuantityPricing
                                unitCost={quantity.unit_cost}
                                label={quantity.label}
                                price={quantity.price}
                                saving={quantity.saving}
                                pricePer={quantity.price_per}
                            />
                        )}
                    </Selector.Card>
                );
            })}
        </Selector>
    );
};

export default RegularQuantitySelector;
