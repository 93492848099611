import React, { ComponentProps } from 'react';
import clx from 'classnames';

import Button from '../Button/Button';
import LoadingIcon from '../../Icons/LoadingIcon/LoadingIcon';

interface Props extends ComponentProps<typeof Button> {
    loading?: boolean;
}

const ButtonBusy = ({ loading, onClick, className, children, ...rest }: Props) => {
    const handleClick = loading ? (e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault() : onClick;

    const classNames = clx('relative', className, {
        '!cursor-wait': loading,
    });

    const ariaLabel = loading ? 'Loading' : undefined;

    return (
        <Button onClick={handleClick} className={classNames} aria-disabled={loading} aria-label={ariaLabel} {...rest}>
            <span className={loading ? 'invisible' : undefined}>{children}</span>
            {loading ? (
                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <LoadingIcon />
                </span>
            ) : null}
        </Button>
    );
};

export default ButtonBusy;
