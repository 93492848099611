import { useMemo } from 'react';

import { ProductVariant } from '../../../../../types/api/products/Product';
import formatQuantityLabel from '../../../helpers/formatQuantityLabel';

/**
 * Sorts the quantities into correct order, smallest to biggest. It changes the existing label to a formatted
 * correctly formatted label to be display. Returns the quantities and the percentage saving between the
 * smallest and largest quantities.
 */
const useFormatQuantitiesForDisplay = (selectedVariant: ProductVariant) => {
    const formattedQuantities = useMemo(() => {
        let formattedQuantities = selectedVariant.quantities.sort((a, b) => a.quantity - b.quantity);
        formattedQuantities = formattedQuantities.map((quantity) => ({
            ...quantity,
            label: formatQuantityLabel(quantity),
        }));

        return {
            quantities: formattedQuantities,
        };
    }, [selectedVariant]);

    return formattedQuantities;
};

export default useFormatQuantitiesForDisplay;
