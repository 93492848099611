import { useEffect, useRef, useState } from 'react';

/**
 * @param {number} greaterThan - a number to check the size against
 * @param {boolean} initialValue - If the initial result should be true or false.
 * @return {array} - [isGreaterThan] - brings back a bool if we are greater than the size passed in.
 */
const useWindowSizeCheck = (greaterThan, initialValue = false) => {
    const [isGreaterThan, setIsGreaterThan] = useState(initialValue);
    const timeout = useRef();

    useEffect(() => {
        const checkWindowSize = () => {
            clearTimeout(timeout.current);

            timeout.current = setTimeout(() => {
                if (window.innerWidth > greaterThan) {
                    setIsGreaterThan(true);
                } else {
                    setIsGreaterThan(false);
                }
            }, 200);
        };

        window.addEventListener('resize', checkWindowSize);

        checkWindowSize();

        /** on unmount clean up the timeout and the listener */
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []);

    return [isGreaterThan];
};

export default useWindowSizeCheck;
