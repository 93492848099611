import React from 'react';
import { useRouter } from 'next/navigation';

import { useSelectedQuantityContext, useSelectedVariantContext, useTreatmentContext } from '../../../TreatmentSelectorContext/TreatmentSelectorContext';
import { setConsultationSessionData } from '../../../../../Consultation/hooks/useStartConsultation';

import Button from '../../../../../_ui/_blocks/Buttons/Button/Button';

type ButtonProps = React.ComponentProps<typeof Button>;

interface Props extends Omit<ButtonProps, 'children' | 'title' | 'href' | 'type'> {
    conditionSlug: string;
}

const ContinueToAssessmentButton = ({ conditionSlug, ...rest }: Props) => {
    const { name, id } = useSelectedVariantContext();
    const treatment = useTreatmentContext();
    const selectedVariant = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();

    const router = useRouter();

    const handleClick = () => {
        const quantity = selectedQuantity?.quantity || 0;

        // !NOTE!: treatmentId has been removed because if we send it, the treatment recommendation panel
        // wont be displayed.
        setConsultationSessionData({
            conditionId: treatment!.condition_id,
            selectedVariant,
            selectedQuantityInfo: selectedQuantity || undefined,
            selectedTreatment: treatment!,
        });

        router.push(`/${conditionSlug}/request-consultation?variant=${id}&quantity=${quantity}`);
    }

    return (
        <Button
            {...rest}
            type="button"
            onClick={handleClick}
            title={`Complete an assessment to add ${name} to your basket`}
        >
            Continue to Assessment
        </Button>
    );
};

export default ContinueToAssessmentButton;
