import React from 'react';
import { RadioGroup } from '@headlessui/react';

import SelectorCard from './SelectorCard/SelectorCard';

type SelectorCardProps = React.ComponentProps<typeof SelectorCard>;

interface Props {
    label?: string;
    value: SelectorCardProps['value'];
    onChange: (value: SelectorCardProps['value']) => void;
    children: React.ReactNode;
    'data-testid'?: string;
}

/**
 * Selector component that wraps a list of SelectorCard components. Uses headlessui's RadioGroup component.
 * @see https://headlessui.com/react/radio-group
 */
const Selector = ({ label, value, onChange, children, ...rest }: Props) => (
    <RadioGroup value={value} onChange={onChange} className="space-y-050" {...rest}>
        {label
            ? <RadioGroup.Label className="block font-700 type-090 leading-200">{label}</RadioGroup.Label>
            : null}
        {children}
    </RadioGroup>
);

Selector.Card = SelectorCard;

export default Selector;
