import React, { Fragment } from 'react';
import { RadioGroup } from '@headlessui/react';
import clx from 'classnames';

import styles from './SelectorCard.module.css';

interface RadioGroupOptionState {
    checked: boolean;
}

interface Props {
    children: (state: RadioGroupOptionState) => React.ReactNode;
    value: number;
    title: string;
}

/**
 * Selectable cards for selectors found in the treatment selector.
 * @see https://headlessui.com/react/radio-group
 */
const SelectorCard = ({ children, value, title }: Props) => (
    <RadioGroup.Option value={value} title={title} className={styles.card}>
        {({ checked }) => {
            const indicatorClassName = clx(styles.indicator, {
                [styles.indicatorActive]: checked,
            });

            return (
                <Fragment>
                    <div className={indicatorClassName} />
                    {children({ checked })}
                </Fragment>
            );
        }}
    </RadioGroup.Option>
);

export default SelectorCard;
