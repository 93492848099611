import { ProductVariant } from '../../../../../../../../types/api/products/Product';

export interface OutOfStockBlocker {
    type: 'OUT_OF_STOCK';
}

/**
 * Returns the out of stock blocker if a variant has no quantities.
 */
const getOutOfStockBlocker = (variant: ProductVariant) => {
    if (variant.quantities.length > 0) {
        return null;
    }

    return {
        type: 'OUT_OF_STOCK',
    };
};

export default getOutOfStockBlocker;
