import React from 'react';

import SvgWrapper from './SvgWrapper';

type Props = React.ComponentProps<typeof SvgWrapper>;

const CheckmarkThin = (props: Props) => (
    <SvgWrapper {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.111 1.99222C46.6676 2.60583 46.6214 3.55445 46.0078 4.11103C33.1285 15.7933 25.3826 29.1432 19.6877 45.4934C19.5166 45.9848 19.1029 46.3524 18.5948 46.4647C18.0866 46.5769 17.5566 46.4178 17.1944 46.0442L1.92315 30.2959C1.34645 29.7012 1.36105 28.7515 1.95578 28.1748C2.5505 27.5981 3.50014 27.6127 4.07685 28.2075L17.6637 42.2188C23.3851 26.2934 31.4149 13.2974 43.9922 1.88897C44.6058 1.33239 45.5545 1.37862 46.111 1.99222Z"
        />
    </SvgWrapper>
)

export default CheckmarkThin;
