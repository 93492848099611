import React from 'react';

import formatPrice from '../../../../helpers/formatPrice';
import { ProductsQuantity } from '../../../../types/api/products/ProductsQuantity';

import Typography from '../../../_ui/_blocks/Typography/Typography';

interface Props {
    unitCost: ProductsQuantity['unit_cost'];
    price: ProductsQuantity['price'];
    saving?: ProductsQuantity['saving'];
    label?: string;
    as?: 'div' | 'li';
    pricePer?: ProductsQuantity['price_per'];
}

/**
 * Pricing of a quantity to be displayed.
 */
const QuantityPricing = ({ as: Tag = 'div', unitCost, label, price, saving, pricePer }: Props) => (
    <Tag>
        <Typography
            as="div"
            size="090"
            className="flex gap-100 justify-between items-center"
        >
            {label}
            <span>{formatPrice(price)}</span>
        </Typography>
        <Typography
            as="div"
            size="080"
            color="quiet"
            className="flex gap-100 justify-between items-center"
        >
            {pricePer || `${formatPrice(unitCost)} per pack`}
            {saving
                ? <span className="text-products-saving">Save {formatPrice(saving)}</span>
                : null}
        </Typography>
    </Tag>
);

export default QuantityPricing;
