import React, { useMemo } from 'react';
import Image from 'next/image';

import getStarRating from './helpers/getStarRating';

enum Sizes {
    small = 'w-[0.75rem] h-[0.75rem]',
    medium = 'w-[1rem] h-[1rem]',
}

type ImageProps = React.ComponentProps<typeof Image>;

interface Props extends Pick<ImageProps, 'loading'> {
    rating: number; // Number between 1 and 5
    className?: string; // Layout only classes
    size?: keyof typeof Sizes;
}

/**
 * Displays star SVGs in img tags to indicate a rating out of 5.
 */
const StarRating = ({ rating, className = '', size = 'small', loading }: Props) => {
    const stars = useMemo(() => getStarRating(rating), [rating]);

    const sizing = size === 'small' ? 12 : 16;

    return (
        <div className={`flex gap-[2px] ${className}`}>
            {stars.map((star, index) => (
                <Image
                    key={index}
                    className={Sizes[size]}
                    src={star}
                    loading={loading}
                    alt="Star"
                    width={sizing}
                    height={sizing}
                />
            ))}
        </div>
    );
};

export default StarRating;
