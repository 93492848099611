import React from 'react';

import { PortableText, type PortableTextComponents } from 'next-sanity';
import { SanityBlockContent } from '../../../../helpers/text/sanityBlockContentToString';

import Typography from '../Typography/Typography';

interface Props extends Pick<React.ComponentProps<typeof Typography>, 'color' | 'underline' | 'italic' | 'lineThrough' | 'className'> {
    highlightColor?: 'highlight' | 'accent';
    title: string | SanityBlockContent;
    desktopFontSize?: 'type-1100' | 'type-1000';
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const defaultHighlightColor = 'accent';

export const titleHighlightedComponents = (highlightColor = defaultHighlightColor) => ({
    block: {
        normal: ({ children }) => (
            children
        ),
    },
    marks: {
        highlight: ({ children }) => (
            <span
                className={`font-500 ${highlightColor === 'accent' ? 'text-content-accent' : 'text-content-highlight'}`}
            >
                {children}
            </span>
        ),
    },
}) as PortableTextComponents;

/**
 * This is a component that uses Portable Text to render a title.
 * We have a highlight mark setup for this so that we can highlight parts of the title.
 */
export default function HighlightedTitle({
    as = "h1",
    className,
    title,
    desktopFontSize = 'type-1100',
    highlightColor = defaultHighlightColor,
    ...rest
}: Props) {
    if (typeof title === 'string') {
        // TODO: Once released and stable, re-enable this error and delete the render in this if.
        // throw new Error('Children is being passed in as a string. It should be an instance of BlockContent.');
        return (
            <Typography
                as={as}
                typeset="title"
                size="700"
                lineHeight="600"
                className={`md:type-800 md:leading-700 lg:${desktopFontSize} ${className || ''}`}
                {...rest}
            >
                {title}
            </Typography>
        );
    }

    return (
        <Typography
            as={as}
            typeset="title"
            size="700"
            lineHeight="600"
            className={`md:type-800 md:leading-700 lg:${desktopFontSize} ${className || ''}`}
            {...rest}
        >
            <PortableText components={titleHighlightedComponents(highlightColor)} value={title} />
        </Typography>
    );
}
