import Patient from '../../../../../../types/api/patient/Patient';
import { ProductVariant } from '../../../../../../types/api/products/Product';
import { ProductsQuantity } from '../../../../../../types/api/products/ProductsQuantity';
import getDefaultQuantity from './helpers/getDefaultQuantity/getDefaultQuantity';
import getDefaultVariant, { InitialVariant } from './helpers/getDefaultVariant/getDefaultVariant';

export interface VariantQuantityReducerState {
    refills: Patient['refills'];
    isRefill: boolean;
    variants: ProductVariant[];
    initialVariant?: InitialVariant;
    variant: ProductVariant;
    quantity: ProductsQuantity | null;
}

interface UpdateVariantAction {
    type: 'UPDATE_VARIANT';
    payload: {
        variant: ProductVariant;
    };
}

interface UpdateQuantityAction {
    type: 'UPDATE_QUANTITY';
    payload: {
        quantity: ProductsQuantity;
    };
}

export type ReducerAction = UpdateVariantAction | UpdateQuantityAction;

/**
 * Returns the quantity of a refill found that matches the currently selected variant.
 */
export const getRefillQuantity = (variant: ProductVariant, refills: Patient['refills']) =>
    refills.find(({ reference }) => reference === variant.id)?.quantity;

/**
 * Function used to create the initial state for the variant quantity reducer. Default variant and quantity
 * are calculated here.
 */
export const createInitialVariantQuantityReducerState = ({ variants, initialVariant, refills }: VariantQuantityReducerState) => {
    const variant = getDefaultVariant(variants, initialVariant);
    const initialQuantity = getRefillQuantity(variant, refills);
    return {
        variants,
        variant,
        refills,
        isRefill: !!initialQuantity,
        quantity: getDefaultQuantity(variant, initialQuantity),
    };
};

/**
 * Function used to update the variant and quantity in the reducer state.
 */
export const variantQuantityReducer = (state: VariantQuantityReducerState, action: ReducerAction) => {
    /* eslint-disable indent */
    /* eslint-disable no-case-declarations */
    switch (action.type) {
        case 'UPDATE_VARIANT':
            const initialQuantity = getRefillQuantity(action.payload.variant, state.refills);
            return {
                ...state,
                isRefill: !!initialQuantity,
                variant: action.payload.variant,
                quantity: getDefaultQuantity(action.payload.variant, initialQuantity),
            };
        case 'UPDATE_QUANTITY':
            return {
                ...state,
                quantity: action.payload.quantity,
            };
        default:
            return state;
    }
    /* eslint-enable no-case-declarations */
    /* eslint-enable indent */
};
