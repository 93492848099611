import React from 'react';

import { useSelectedQuantityContext } from '../TreatmentSelectorContext/TreatmentSelectorContext';
import formatPrice from '../../../../helpers/formatPrice';

import Typography from '../../../_ui/_blocks/Typography/Typography';

interface Props {
    price?: number;
}

const SelectorPrice = ({ price = 0 }: Props) => {
    const selectedQuantity = useSelectedQuantityContext();

    return (
        <Typography as="div" weight="700" size="200" className="flex justify-between items-end gap-100">
            Total
            <span className="text-products-price">
                {formatPrice(price || selectedQuantity?.price || 0)}
            </span>
        </Typography>
    );
};

export default SelectorPrice;
