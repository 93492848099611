import { ProductsQuantity } from '../../../../../../types/api/products/ProductsQuantity';

/**
 * Filters the treatment quantities by the ones that are featured. If none are featured, use all the
 * quantities. Then limit them to a maximum of 5 quantities to show.
 */
const useFilterQuantitiesByFeatured = (quantities: ProductsQuantity[]) => {
    const maxDisplayed = 5;

    let featuredQuantities = quantities.filter(({ featured }) => featured);
    if (!featuredQuantities.length) {
        featuredQuantities = quantities;
    }

    return featuredQuantities.slice(0, maxDisplayed);
};

export default useFilterQuantitiesByFeatured;
