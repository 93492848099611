import React from 'react';

import { useSelectedQuantityContext, useBasketBlockerContext } from '../../TreatmentSelectorContext/TreatmentSelectorContext';

import ButtonBusy from '../../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';
import OpenBasketButton from '../Buttons/OpenBasketButton/OpenBasketButton';
import SelectAndContinueButton from '../Buttons/SelectAndContinueButton/SelectAndContinueButton';

type ButtonBusyProps = React.ComponentProps<typeof ButtonBusy>;

type Props = Pick<ButtonBusyProps, 'className' | 'size' | 'loading'>;

/**
 * Decides what submission button to show during the consultation process for the treatment selector.
 */
const ConsultationButtons = ({ className, loading }: Props) => {
    const selectedQuantity = useSelectedQuantityContext();
    const blockers = useBasketBlockerContext();

    const blockerTypes = blockers.map(({ type }) => type);
    const quantity = selectedQuantity?.quantity || 0;

    const props = {
        className,
        size: 'large' as ButtonBusyProps['size'],
        loading,
    };

    if (blockerTypes.includes('ALREADY_IN_BASKET') || blockerTypes.includes('BLOCKED_BY_BASKET')) {
        return <OpenBasketButton {...props} />;
    }

    if (blockerTypes.length) {
        return null;
    }

    return <SelectAndContinueButton {...props} disabled={!quantity} />;
};

export default ConsultationButtons;
