import { ProductVariant } from '../../../../../../../../types/api/products/Product';
import { Basket } from '../../../../../../../../types/api/basket/Basket';

export interface AlreadyInBasketBlocker {
    type: 'ALREADY_IN_BASKET';
}

/**
 * Checks if a treatment variant is already in the basket.
 */
const getAlreadyInBasketBlocker = (variantId: ProductVariant['id'], basket: Basket) => {
    const basketItems = basket?.items || [];
    const isInBasket = basketItems.find(({ reference }) => reference === variantId);

    return isInBasket
        ? {
            type: 'ALREADY_IN_BASKET',
        }
        : null;
};

export default getAlreadyInBasketBlocker;
