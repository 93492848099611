export enum Genders {
    male = 'male',
    female = 'female',
    maleIdentifiesFemale = 'male-identifies-female',
    femaleIdentifiesMale = 'female-identifies-male',
    na = 'n/a',
}

export const HumanReadableGenders = {
    male: 'Male',
    female: 'Female',
    'male-identifies-female': 'Trans Female (Male at birth)',
    'female-identifies-male': 'Trans Male (Female at birth)',
    'n/a': 'N/A',
} as const;

export type GenderKeys = keyof typeof HumanReadableGenders;
