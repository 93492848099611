import { ProductVariant } from '../../../../../../../types/api/products/Product';

/**
 * Calculates the number of pills required per the number of days stated.
 */
const calculateNumberOfPillsByDays = (numberOfDays: number, modifiers: ProductVariant['timed_modifiers']) => {
    if (!numberOfDays) return 0;

    const perDay = modifiers.daily || 1;

    let pills = 0;
    if (modifiers.before) pills += modifiers.before;
    if (modifiers.after) pills += modifiers.after;

    // Times the number of days given by the number of pills needed per day.
    pills += perDay * numberOfDays;

    // We round up with ceil rather than down with floor because you can't have eg. 1.486... of a pill, so 2
    // pills would be needed.
    return Math.ceil(pills);
};

export default calculateNumberOfPillsByDays;
