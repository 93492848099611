import { useMemo } from 'react';

import { ProductVariant } from '../../../../../../../types/api/products/Product';
import calculateNumberOfDaysByPills from '../../helpers/calculateNumberOfDaysByPills/calculateNumberOfDaysByPills';

const useMaximumNumberOfPills = (selectedVariant: ProductVariant, numberOfPills: number | string) => {
    const maxNumberOfPills = selectedVariant.timed_modifiers.max;

    // The maximum number of days a user can enter based on the maximum number of pills.
    const maxNumberOfDays = useMemo(() => {
        const { timed_modifiers: modifiers } = selectedVariant;
        return calculateNumberOfDaysByPills(modifiers.max, modifiers);
    }, [selectedVariant]);

    // Is the number of pills chosen greater than the max number of pills allowed.
    const isCurrentNumberOfPillsAllowed = useMemo(() => {
        if (!selectedVariant.timed_modifiers.max || typeof numberOfPills === 'string') {
            return true;
        }

        return numberOfPills <= selectedVariant.timed_modifiers.max;
    }, [selectedVariant, numberOfPills]);

    return {
        maxNumberOfPills,
        maxNumberOfDays,
        isCurrentNumberOfPillsAllowed,
    };
};

export default useMaximumNumberOfPills;
