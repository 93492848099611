import React from 'react';

import {
    useBasketBlockerContext,
    useSelectedQuantityContext,
    useSelectedVariantContext,
} from '../TreatmentSelectorContext/TreatmentSelectorContext';
import { ProductsQuantity } from '../../../../types/api/products/ProductsQuantity';

export type OnSubmitData = {
    quantity: ProductsQuantity;
    variant: ReturnType<typeof useSelectedVariantContext>;
};

export type OnSubmit = (data: OnSubmitData) => void;

interface Props {
    onSubmit: OnSubmit;
    children: React.ReactNode;
    className?: string;
}

/**
 * Form component that handles the submission of the treatment selector.
 */
const TreatmentSelectorForm = ({ onSubmit, className, children }: Props) => {
    const selectedVariant = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();
    const blockers = useBasketBlockerContext();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Button should be disabled if there are blockers or no quantity. The disabled button will stop the
        // form submission either by click or enter key. This is just in case the user is able to submit the
        // form some how.
        if (!selectedQuantity?.quantity || blockers.length) {
            return;
        }

        onSubmit({
            quantity: selectedQuantity,
            variant: selectedVariant,
        });
    };

    return (
        <form onSubmit={handleSubmit} className={className}>
            {children}
        </form>
    );
};

export default TreatmentSelectorForm;
